import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

export default function LoadingLogo(props) {
  const { name, logo } = useSelector(
    (state) => ({
      name: state.ui.name,
      logo: state.ui.logo,
    }),
    shallowEqual
  );
  return (
    <img src={logo || '/64x64.png'} alt="image" className="w-20 self-center" />
  );
}
