import React, { createContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../services/redux/actions';
import { ls } from '../services/util';
import FullPageLoading from '../components/loading/full-page-loading';

//TODO move to central location / another file
const SSR_LIST = [
  '/',
  '/404',
  '/about',
  '/influencer',
  '/brand',
  '/agency',
  '/pricing',
  '/privacy-policy',
  '/terms-of-service',
  '/cookie-policy',
  '/explore',
  '/explore/[tab]',
  '/career',
  '/campaign-standards',
  '/community-standards',
  '/review',
  '/trust',
  '/contact',
  '/media',
  '/sitemap',
  '/blogs',
  '/blog/[id]',
  '/how-it-works',
  '/help',
  // '/login',
  // '/register',
];

const ALLOW_LIST = [
  '/login',
  '/register',
  '/auth',
  '/login/old',
  '/logout',
  '/register/old',
  '/whoareyou',
  '/forgot-password',
  '/reset-password',
  '/reset-password//inflosousers/reset-password/',
  '/reset-password/inflosousers/reset-password/',
  '/reset-password//inflosousers/reset-password',
  '/reset-password/inflosousers/reset-password',
  '/activate-email',
  '/register-success',
];

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
// const getUserFromServer = () => sleep(300).then(() => ({username: 'elmo'}))
const getUserFromServer = async () => {
  // return await actions.user.getUser();
};
const login = () => sleep(300).then(() => ({ status: 'SUCCESS' }));

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const isClient = typeof location !== 'undefined';
  const state = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );
  const { user } = state;

  // const store = initializeStore();
  // const [state, setState] = React.useState({
  //   status: 'pending',
  //   error: null,
  //   user: null,
  // })
  const dispatch = useDispatch();
  // if(actions.user && actions.user.getUser) {
  //   dispatch(actions.user.getUser());
  // }

  useEffect(() => {
    (async () => {
      // const d = await dispatch(actions.ui.loadDomain());
      const t = await dispatch(actions.ui.loadTheme());
    })();
  }, []);

  React.useEffect(() => {
    if (actions.user && actions.user.getUser) {
      // setTimeout(()=>{
      async function a(user) {
        try {
          const type = user.usertype ? user.usertype.toLowerCase() : 'all';
          const userx = await dispatch(actions.user.getUser(type));
          // const userx = await dispatch(actions.user.getUser());
          if (userx.usertype) {
            await dispatch(actions.navigation.set(userx.usertype));
          }
          if (userx.has_domain) {
            await dispatch(actions.ui.loadTheme(userx.domain));
          }
        } catch (e) {}
      }

      // if(user && user.usertype) {
      //   console.log('us', user.usertype);
      // }
      // if(user && !user.usertype) {
      (async () => {
        await a(user);
      })();
      // }
      // }, 5000);
    }
    // getUserFromServer().then(
    //   user => setState({status: 'success', error: null, user}),
    //   error => setState({status: 'error', error, user: null}),
    // )
    // }, [user.usertype])
  }, [user.usertype, user.type_id]);

  const router = useRouter();

  const LOCALES = ['en', 'de', 'it', 'fr', 'es'];

  let PATHNAME_NO_LOCALE = router.pathname;

  LOCALES.map((l) => {
    PATHNAME_NO_LOCALE = PATHNAME_NO_LOCALE.replace('/' + l + '/', '/');
  });

  if (isClient) {
    if (user && user.status === 'pending') {
      return <FullPageLoading />;
    }

    if (SSR_LIST.indexOf(PATHNAME_NO_LOCALE) > -1) {
      //do nothing render whatever it is
    } else if (
      user &&
      user.admin &&
      (location.pathname === '/login' || location.pathname === '/login/old')
    ) {
      router.push('/madmin');
      return <FullPageLoading />;
    } else if (
      user &&
      user.admin &&
      location.pathname.indexOf('/madmin') === -1
    ) {
      router.push('/madmin/dashboard');
      return <FullPageLoading />;
    } else if (user && user.admin) {
      //do nothing render whatever it is
    } else if (!user.id && location.pathname === '/whoareyou') {
      router.push('/login');
      return <FullPageLoading />;
    } else if (
      user.id &&
      (!user.type || user.type === 'all') &&
      location.pathname !== '/whoareyou'
    ) {
      router.push('/whoareyou');
      return <FullPageLoading />;
    } else if (
      user.id &&
      user.type &&
      user.type !== 'all' &&
      (location.pathname === '/login/old' ||
        location.pathname === '/login' ||
        location.pathname === '/register' ||
        location.pathname === '/whoareyou')
    ) {
      // router.push('/dashboard');
      const return_url = ls.getItem('return_url') || '/dashboard';
      if (return_url) {
        setTimeout(() => {
          ls.removeItem('return_url');
        }, 1000);

        const goforit =
          ALLOW_LIST.filter((u) => return_url.indexOf(u) === -1).length === 0;

        if (goforit) {
          router.push(return_url);
        } else {
          router.push('/dashboard');
        }
      } else {
        router.push('/dashboard');
      }

      return <FullPageLoading />;
    } else if (
      !user.admin &&
      !user.id &&
      ALLOW_LIST.indexOf(location.pathname) === -1
    ) {
      ls.setItem('return_url', location.pathname);
      router.push('/login');
      return <FullPageLoading />;
    }
  } else {
    if (SSR_LIST.indexOf(PATHNAME_NO_LOCALE) > -1) {
      //THE SSR
    } else {
      return <FullPageLoading />;
    }
  }

  return (
    <AuthContext.Provider value={state}>
      {state.status === 'pending' ? (
        'Loading...'
      ) : state.status === 'error' ? (
        <div>
          Oh no
          <div>
            <pre>{state.error.message}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export function useAuthState() {
  const state = React.useContext(AuthContext);
  const isPending = state.status === 'pending';
  const isError = state.status === 'error';
  const isSuccess = state.status === 'success';
  const isAuthenticated = state.user && isSuccess;
  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
  };
}

export function useUser() {
  const { user } = React.useContext(AuthContext);
  return { user };
}

export async function signIn(service, options) {
  const { user } = useAuthState();
  // const router = useRouter();
  const response = await login(service, options);
  if (response.status === 'SUCCESS') {
    const user = getUserFromServer();
    setUser(user);
    return user;
  } else {
    return null;
  }
  // router.push('/dashboard');
}

export async function signOut() {}

function setUser(user) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('infloso.auth.user', JSON.stringify(user));
  }
}

async function getUser() {
  let user = localStorage.getItem('infloso.auth.user');
  if (user) {
    user = JSON.parse(user);
    // }else{
    //   user = await getUserFromServer();
  }
  return user;
}
