import polyfills from '../lib/polyfills';
import React from 'react';
import Head from 'next/head';
import '../scss/tailadmin.css';
import '../scss/styles.scss';
import '../scss/_components.scss';
import '../scss/_layouts.scss';
import '../scss/_palettes.scss';
import '../scss/rc-slider.scss';
import '../scss/react-datetime.scss';
import '../scss/react-notifications.scss';
import '../scss/components/html-editor.scss';
import { AuthProvider } from '../lib/auth';
import { initializeStore } from '../services/store';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { pageview } from '../lib/analytics';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { NextIntlProvider } from 'next-intl';
import {Crisp} from "crisp-sdk-web";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// Router.events.on('routeChangeStart', () => NProgress.start())
// Router.events.on('routeChangeComplete', () => NProgress.done())
// Router.events.on('routeChangeError', () => NProgress.done())
if (
  typeof document !== 'undefined' &&
  document.location.hostname !== 'dev.infloso.com' &&
  document.location.hostname !== 'pre.infloso.com'
) {
  Sentry.init({
    dsn: 'https://0219d91f7ec94f768a22fc49d4ebc061@o937097.ingest.sentry.io/5887502',
    environment: 'production',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    release: 'infloso@' + process.env.VERSION + '-' + process.env.BRANCH,
    tracesSampleRate: 1.0,

    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  });

  // Sentry.init({
  //   dsn: 'https://0219d91f7ec94f768a22fc49d4ebc061@o937097.ingest.sentry.io/5887502',
  //   integrations: [new Integrations.BrowserTracing()],
  //   environment: 'production',
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   release: 'infloso@' + process.env.VERSION + '-' + process.env.BRANCH,
  //   tracesSampleRate: 1.0,
  // });
}

// export function reportWebVitals(metric) {
//   console.log(metric)
// }

const MyApp = ({ Component, pageProps }) => {
  // const user = typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem("user"));
  // if(typeof location === 'undefined' ){
  //   return <></>;
  // }
  //
  //
  // if(!user && location.pathname !== '/login') {
  //   const router = useRouter();
  //   router.push('/login');
  //   return <></>;
  // }

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(()=>{
    if(typeof window !== 'undefined' && window.location.hostname === "www.infloso.com") {
      Crisp.configure("a9cd9cdb-3be5-455a-976b-a0486c1d5e45");
      // $crisp.push(['do', 'chat:hide']);
    }
  },[])

  const store = initializeStore();

  //TODO fix this bad hack once backend is able to change the url
  if (
    typeof location != 'undefined' &&
    location.pathname.indexOf('inflosousers/reset-password') > 0
  ) {
    location.href = location.href.replace('inflosousers/reset-password', '');
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/*<meta name="mobile-web-app-capable" content="yes" />*/}
      </Head>
      <Provider store={store}>
        {/*<TawkMessengerReact*/}
        {/*  propertyId="65302300f2439e1631e5dc0d"*/}
        {/*  widgetId="1hd20hers"/>*/}
        <AuthProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <NextIntlProvider
              formats={{
                dateTime: {
                  short: {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  },
                },
              }}
              messages={pageProps.messages}
              now={new Date(pageProps.now)}
              timeZone="Asia/Calcutta"
            >
              <Component {...pageProps} />
            </NextIntlProvider>
          </MuiPickersUtilsProvider>
        </AuthProvider>
      </Provider>
    </>
  );
};

export default MyApp;
