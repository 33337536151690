import React from 'react';
import LoadingLogo from './loading-logo';
import Loader from '../display/Loader';

export default function FullPageLoading(props) {
  return (
    <div className="h-screen w-full flex items-stretch justify-center">
      <LoadingLogo />
      {/*<Loader/>*/}
    </div>
  );
}
